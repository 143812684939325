import React from "react";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";

const HabeebaBio = () => (

  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "habiba2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Card>
        <CardTitle title="Habeeba Sulthana - Lead Teacher (Toddler Program)" />
        <CardText>
          <Img fluid={data.Image.childImageSharp.fluid} style={{height: '400px', width: '300px', display: 'block', margin: '0 auto'}} />
          <p>&nbsp;</p>
          <p className="md-text-justify">
            Habiba Sulthana holds a master’s degree in Early Childhood Education.
            She also holds teaching experience of 5 yrs in infant/toddler program with
            Montessori child development center with CA technologies in Hyderabad.
          </p>
          <p className="md-text-justify">
            Charismatic and dependable Lead Guide and Academics Supervisor with 6
            years’ experience in delivering Montessori education assistance and
            instruction to various levels of leaners. Committed to providing students
            with necessary tools to achieve academic goals instilling love of application
            of excellent communication and problem-solving skills. Proficient in
            implementing the Montessori curriculum effectively while fostering social
            relations development and self-care skills among children.
          </p>
        </CardText>
      </Card>
    )}
  />
)

export default HabeebaBio;
