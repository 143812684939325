/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import Slider from "react-slick";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import LakhbirBio from "./LakhbirBio";
import HabeebaBio from "./HabeebaBio";
import SameenaBio from "./SameenaBio";
import DeepthiBio from "./DeepthiBio";

import "./TeamSlider.scss";

const settings =   {
  dots: true,
  arrows: true,
  adaptiveHeight: true,
  className: "teamslider",
  dotsClass: "teamdots",
  fade: true,
  autoplay: false,
  autoplaySpeed: 10000,
  infinite: true,
  speed: 1000,
  pauseOnHover: true,
  pauseOnDotsHover: true,
  pauseOnFocus: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="arrow nextArrow"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
    >
      <FontIcon primary iconClassName="fa fa-chevron-right" />
    </div>
  );
}

function PrevArrow(props) {
  const {  onClick } = props;
  return (
    <div
      className="arrow prevArrow"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
    >
      <FontIcon primary iconClassName="fa fa-chevron-left" />
    </div>
  );
}

const MainSlider = () => (
  <Slider {...settings}>
    <LakhbirBio />
    <HabeebaBio />
  </Slider>
)

export default MainSlider;
