import React from "react";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";

const LakhbirBio = () => (

  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "lakhbir2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Card>
        <CardTitle title="Lakhbir Deol - Program Director" />
        <CardText>
          <Img fluid={data.Image.childImageSharp.fluid} style={{height: '400px', width: '300px', display: 'block', margin: '0 auto'}} />
          <p>&nbsp;</p>
          <p className="md-text-justify">
            Lakhbir, brings with her an experience of over 20 years in the field of education.
            She is a dedicated teacher and professional. She has extensive experience in the area
            of infant/toddler Montessori and primary environments, language development and
            management of corporate early learning centers. For the last five years, she had been
            managing the corporate Child Development Center for CA Technologies in Hyderabad.
          </p>
          <p className="md-text-justify">
            Lakhbir earned her masters degree in Psychology from Punjabi University Patiala with
            specialization in Developmental Psychology. Because of her keen interest in the field of
            education and child development she pursued training from
            &nbsp;
            <b>AMI (Association Montessori Internationale), Hyderabad</b>
            &nbsp;
            and earned her certification as a
            &nbsp;
            <b>Montessori Directress</b>
            . She has successfully run early childhood centers in Punjab and Hyderabad. She is active in
            Montessori Communities and is an advocate for effective management of programs,
            school ethics and Montessori Pedagogy.
          </p>
          <p className="md-text-justify">
            She strongly believes in the philosophy of Dr. Maria Montessori.
          </p>
        </CardText>
      </Card>
    )}
  />
)

export default LakhbirBio;
