import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";

import TeamSlider from '../../components/TeamSlider';
import Layout from '../../layout'

const Team = () => (
  <Layout>
    <Grid stacked>
      <Cell
        align='middle'
        desktopSize={8}
        tabletSize={6}
      >
        <TeamSlider />
      </Cell>
    </Grid>
  </Layout>
)

export default Team
